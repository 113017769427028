import { apiClient } from '../../provider';
import schema from '@/modules/about/schema.graphql';

export async function getVersion() {
  const res = await apiClient.query({
    query: schema.getVersion,
    fetchPolicy: 'no-cache'
  });
  return res.data.getVersion;
}

export async function getTimezone() {
  const res = await apiClient.query({
    query: schema.getTimezone,
    fetchPolicy: 'no-cache'
  });
  return res.data.getTimezone;
}

export async function getTimezoneShift() {
  const res = await apiClient.query({
    query: schema.getTimezoneShift,
    fetchPolicy: 'no-cache'
  });
  return res.data;
}
